<template>
  <div v-if="getVisibleAtomSurvey">
    <div class="popup-container">
      <div class="popup-header">
        Hangi saatlerde size ulaşalım?
      </div>
      <div class="popup-question">
        {{ questionAtomSurvey.question }}
      </div>
      <img class="popup-img " :src="this.atomSurveyDetail.image" />
      <div class="option-container">
        <button
          v-for="(item, i) in options"
          :key="i"
          class="option"
          :class="optionClass(item)"
          @click="onSelectedAnswer(item)"
        >
          <span>{{ item.option }}</span>
        </button>
      </div>
    </div>
    <div @click="onSubmitAnswer" class="atom_survey_ok">
      <span class="ok_btn_text"> TAMAM </span>
    </div>
  </div>
</template>
<script>
import GameApi from '@/services/Api/gamification';
import { mapGetters } from 'vuex';

export default {
  name: 'AtomSurveyPopup',
  computed: {
    ...mapGetters('app', ['getPopups']),
    getVisibleAtomSurvey() {
      return this.getPopups.popupId == 5 && this.$route.fullPath == '/secure/feed';
    },
  },
  data() {
    return {
      atomSurveyDetail: {},
      selectedAnswer: {},
      questionAtomSurvey: '',
      options: [],
    };
  },
  watch: {
    getPopups: {
      handler(val) {
        if (val.popupId == 5) {
          this.getAtomSurveyDetail();
        }
      },
    },
  },
  methods: {
    getAtomSurveyDetail() {
      GameApi.getSurveyDetail(this.getPopups?.popupDetail?.surveyId).then(res => {
        this.atomSurveyDetail = res.data.Data.surveyDetail;
        this.questionAtomSurvey = this.atomSurveyDetail?.questions[0] ?? [];
        this.options = this.atomSurveyDetail?.questions[0]?.options ?? [];
      });
    },
    finishSurvey() {
      GameApi.playWinFinishSurvey(this.atomSurveyDetail.surveyTakenId).then(res => {
        if (!res.data.Data.successful) return;
        if (this.atomSurveyDetail.surveyType === 2) {
          const successModal = {
            isModalOpened: true,
            modalText: this.atomSurveyDetail.afterSurveyHtml
              ? this.atomSurveyDetail.afterSurveyHtml
              : 'Anket başarıyla tamamlanmıştır.',
            modalType: 'surveyAlert',
            alertType: 'apply',
            firstButtonText: 'TAMAM',
            surveyType: 1,
          };
          this.$store.dispatch('app/setModalConfig', successModal);
        }
      });
    },
    optionClass(buttonAnswer) {
      let opStyle = '';
      if (buttonAnswer === this.selectedAnswer) {
        opStyle = 'selected-option';
      }
      return opStyle;
    },
    onSelectedAnswer(selectedAnswer) {
      this.selectedAnswer = selectedAnswer;
    },
    onSubmitAnswer() {
      if (!this.selectedAnswer.optionId) return;
      const request = {
        surveyTakenId: this.atomSurveyDetail.surveyTakenId,
        surveyId: this.atomSurveyDetail.surveyId,
        answers: [
          {
            questionId: this.questionAtomSurvey.questionId,
            optionId: this.selectedAnswer.optionId,
          },
        ],
      };
      GameApi.saveSurvey(request).then(() => {
        this.finishSurvey();
      });
      const eventData = this.setEventData(true);
      this.$emit('closePopup', eventData, { type: 'OkClick' });
    },
    setEventData(isClick) {
      return {
        event: 'cEvent',
        category: 'Pop Up',
        action: isClick ? 'Click' : 'Close',
        label: 'AtomDynamicSurvey',
        value: 0,
        isNonInteraction: false,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-container {
  position: absolute;
  width: 430px;
  height: 510px;
  z-index: 26;
  top: 80px;
  left: 50%;
  background-color: #fff;
  border: #d02d2c 10px solid;
  transform: translate(-50%, 0);
  text-align: -webkit-center;
}
.atom_survey_ok {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 430px;
  height: 69px;
  object-fit: contain;
  border-radius: 4px;
  position: absolute;
  z-index: 26;
  top: 600px;
  left: 50%;
  cursor: pointer;
  background-color: #fff;
  transform: translate(-50%, 0);
}
.ok_btn_text {
  width: 81px;
  height: 26px;
  object-fit: contain;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e5472d;
}
.popup-header {
  margin-top: 30px;
  font-size: 24px;
  font-style: Bold;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}
.popup-question {
  margin: 30px 25px 0;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  font-style: Bold;
  text-align: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}
.popup-img {
  display: flex;
  justify-content: center;
}
.option-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}
.option {
  background-color: #fff;
  border: solid 2px #b4c2d3;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: 40%;
  &:focus,
  &:active {
    border-color: #1e1515;
    cursor: pointer;
  }
}

.selected-option {
  background-color: palette-color-level('grey', 30) !important;
  color: palette-color-level('white', 100) !important;
}
</style>
